import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import Vue from 'vue';
import waves from "./waves";
import permission from "./permission";
import mobileVisual from "./mobileVisual";
import number from "./number";
var directives = {
  permission: permission,
  waves: waves,
  mobileVisual: mobileVisual,
  number: number
};
Object.keys(directives).forEach(function (key) {
  Vue.directive(key, directives[key]);
});