var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ConditionPanelItem",
    {
      attrs: {
        span: _vm.span,
        selected: !_vm.emptyValue,
        label: _vm.computedLabel,
      },
    },
    [
      _c(
        "el-input",
        {
          attrs: {
            placeholder: _vm.computedPlaceholder,
            value: _vm.value,
            clearable: "",
          },
          on: {
            input: function (val) {
              return _vm.$emit("update:value", val)
            },
            clear: _vm.onClear,
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onNameChange($event)
            },
          },
        },
        [
          _c("el-button", {
            staticClass: "el-icon-search padding-right-6",
            attrs: { slot: "suffix", type: "text" },
            nativeOn: {
              click: function ($event) {
                return _vm.onNameChange($event)
              },
            },
            slot: "suffix",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }