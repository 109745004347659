import "core-js/modules/es6.number.constructor";
import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
import SystemOrderData from "../../data/SystemOrderData";
var attributes = {
  order_no: {
    type: String,
    label: '订单号',
    filterType: FilterParam.TYPE_NAME
  },
  status: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '状态',
    filterOptions: SystemOrderData.STATUSES
  }
};
var SystemOrderModel = {
  id: Entity.ACCOUNT_SYSTEM_ORDER,
  name: Entity.ACCOUNT_SYSTEM_ORDER_NAME,
  label: Entity.ACCOUNT_SYSTEM_ORDER_LABEL,
  icon: Entity.ACCOUNT_SYSTEM_ORDER_ICON,
  listUri: '/userCenter/order',
  createUri: '/userCenter/createOrder',
  updateUri: null,
  attributes: attributes
};
export default SystemOrderModel;