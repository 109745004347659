import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
var attributes = {
  name: {
    type: String,
    label: '名称',
    filterType: FilterParam.TYPE_NAME
  }
};
var ResTravellerExportModel = {
  id: Entity.RES_TRAVELLER_EXPORT,
  name: Entity.RES_TRAVELLER_EXPORT_NAME,
  label: Entity.RES_TRAVELLER_EXPORT_LABEL,
  icon: Entity.RES_TRAVELLER_EXPORT_ICON,
  listUri: '/resource/touristTable',
  nameAttr: 'name',
  createUri: '/resource/touristTable/add',
  updateUri: '/resource/touristTable/edit',
  attributes: attributes
};
export default ResTravellerExportModel;