var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ConditionPanelItem",
    {
      attrs: {
        span: _vm.span,
        selected: !_vm.emptyValue,
        label: _vm.computedLabel,
      },
    },
    [
      _c("el-date-picker", {
        attrs: {
          type: "daterange",
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "value-format": "yyyy-MM-dd",
          value: _vm.value,
        },
        on: {
          "update:value": function ($event) {
            _vm.value = $event
          },
          input: _vm.onDatePickerChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }