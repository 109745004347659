var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "count-down" },
    [
      _c(
        "el-button",
        {
          directives: [{ name: "waves", rawName: "v-waves" }],
          attrs: { disabled: _vm.disabled },
          on: { click: _vm.handleClick },
        },
        [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }