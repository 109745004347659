import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
var attributes = {
  bus_company_name: {
    type: String,
    label: '名称',
    filterType: FilterParam.TYPE_NAME
  },
  districts: {
    type: Array,
    label: '所在区域',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  }
};
var ResBusCompanyModel = {
  id: Entity.RES_BUS_COMPANY,
  name: Entity.RES_BUS_COMPANY_NAME,
  label: Entity.RES_BUS_COMPANY_LABEL,
  icon: Entity.RES_BUS_COMPANY_ICON,
  nameAttr: 'bus_company_name',
  listUri: '/resource/bus',
  createUri: '/resource/bus/add',
  updateUri: '/resource/bus/edit',
  attributes: attributes
};
export default ResBusCompanyModel;