var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.value, function (tag, index) {
        return _c(
          "div",
          { key: index, staticClass: "tag-wrapper" },
          [
            _vm.valueMapDtatus[index]
              ? _c("el-input", {
                  ref: "saveTagInput" + index,
                  refInFor: true,
                  style: {
                    width: _vm.maxLength * 17 + "px",
                    minWidth: "120px",
                  },
                  attrs: {
                    size: "mini",
                    type: "text",
                    maxlength: _vm.maxLength,
                    "show-word-limit": "",
                  },
                  on: {
                    blur: function ($event) {
                      return _vm.handleInputConfirm(index)
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleInputConfirm(index)
                    },
                  },
                  model: {
                    value: _vm.value[index],
                    callback: function ($$v) {
                      _vm.$set(_vm.value, index, $$v)
                    },
                    expression: "value[index]",
                  },
                })
              : _c(
                  "el-tag",
                  {
                    attrs: {
                      effect: _vm.effect,
                      type: _vm.type,
                      closable: "",
                      size: "mini",
                      "disable-transitions": false,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.showInputCurrent(index)
                      },
                      close: function ($event) {
                        return _vm.handleClose(tag)
                      },
                    },
                  },
                  [_vm._v("\n      " + _vm._s(tag) + "\n    ")]
                ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.value.length < _vm.maxTotal
        ? _c("span", { staticClass: "font-link", on: { click: _vm.addTags } }, [
            _vm._v("+ 添加"),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }