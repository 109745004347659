var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.imgurl
        ? _c(
            "div",
            {
              staticClass: "img-preview",
              style: { width: "" + _vm.imgWidth, height: "" + _vm.imgHeight },
            },
            [
              _c("div", { staticClass: "img-preview-shadow" }, [
                _c(
                  "span",
                  {
                    staticClass: "avatar-uploader__item-preview",
                    on: { click: _vm.handlePictureCardPreview },
                  },
                  [_c("i", { staticClass: "el-icon-zoom-in" })]
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "avatar",
                style: {
                  backgroundImage: "url(" + _vm.imgurl + ")",
                  width: "" + _vm.imgWidth,
                  height: "" + _vm.imgHeight,
                },
              }),
            ]
          )
        : _c("div", [_vm._v("--")]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: _vm.width,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }