import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
var attributes = {
  shop_name: {
    type: String,
    label: '名称',
    filterType: FilterParam.TYPE_NAME
  },
  districts: {
    type: Array,
    label: '所在区域',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  }
};
var ResShopModel = {
  id: Entity.RES_SHOP,
  name: Entity.RES_SHOP_NAME,
  label: Entity.RES_SHOP_LABEL,
  icon: Entity.RES_SHOP_ICON,
  nameAttr: 'shop_name',
  listUri: '/resource/shop',
  createUri: '/resource/shop/add',
  updateUri: '/resource/shop/edit',
  attributes: attributes
};
export default ResShopModel;