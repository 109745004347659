import "core-js/modules/es6.number.constructor";
import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
import TradeData from "../../data/TradeData";
var attributes = {
  name: {
    type: String,
    label: '名称',
    filterType: FilterParam.TYPE_NAME
  },
  event_type: {
    type: Number,
    label: '申请事项',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: TradeData.APPLICATION_TYPES
  },
  status: {
    type: Number,
    label: '状态',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: TradeData.APPLICATION_AUDIT_STATUSES
  },
  only_display: {
    type: Number,
    label: '只看',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: TradeData.APPLICATION_BYS
  }
};
var TradeApplicationModel = {
  id: Entity.TRADE_APPLICATION,
  name: Entity.TRADE_APPLICATION_NAME,
  label: Entity.TRADE_APPLICATION_LABEL,
  icon: Entity.TRADE_APPLICATION_ICON,
  listUri: '/trade/apply',
  createUri: null,
  updateUri: null,
  attributes: attributes
};
export default TradeApplicationModel;