var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plus-btn" },
    [
      _c(
        "el-card",
        {
          staticClass: "flex-center",
          staticStyle: { height: "40px", "margin-top": "10px" },
          attrs: { shadow: "hover" },
          nativeOn: {
            click: function ($event) {
              return _vm.callback($event)
            },
          },
        },
        [
          _c("i", { staticClass: "el-icon-plus fontSize-14" }),
          _vm._v(" "),
          _c("span", { staticClass: "fontSize-14" }, [_vm._v("添加一行数据")]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }