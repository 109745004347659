import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
var attributes = {
  name: {
    type: String,
    label: '名称',
    filterType: FilterParam.TYPE_NAME
  }
};
var DepartmentModel = {
  id: Entity.ACCOUNT_DEPARTMENT,
  name: Entity.ACCOUNT_DEPARTMENT_NAME,
  label: Entity.ACCOUNT_DEPARTMENT_LABEL,
  icon: Entity.ACCOUNT_DEPARTMENT_ICON,
  listUri: '/employee/department',
  createUri: null,
  updateUri: null,
  attributes: attributes
};
export default DepartmentModel;