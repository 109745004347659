import "core-js/modules/es6.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  activeMenu: function activeMenu(state) {
    return state.app.activeMenu;
  },
  formIsChange: function formIsChange(state) {
    return state.app.formIsChange;
  },
  isProcessOrder: function isProcessOrder(state) {
    return state.app.isProcessOrder;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  // { label: "超级管理员", value: 1 }, { label: "管理员", value: 2 }, { label: "普通用户", value: 3 }
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  userId: function userId(state) {
    return state.user.userId;
  },
  today: function today(state) {
    return state.user.today;
  },
  managerId: function managerId(state) {
    return state.user.managerId;
  },
  isInitPass: function isInitPass(state) {
    return state.user.isInitPass;
  },
  identifier: function identifier(state) {
    return state.user.identifier;
  },
  agencyType: function agencyType(state) {
    return state.user.agencyType;
  },
  agencyId: function agencyId(state) {
    return state.user.agencyId;
  },
  agencyName: function agencyName(state) {
    return state.user.agencyName;
  },
  hasProductSite: function hasProductSite(state) {
    return state.user.hasProductSite;
  },
  totalProductCount: function totalProductCount(state) {
    return state.user.totalProductCount;
  },
  publishedProductCount: function publishedProductCount(state) {
    return state.user.publishedProductCount;
  },
  isWxBind: function isWxBind(state) {
    return state.user.isWxBind;
  },
  systemVersion: function systemVersion(state) {
    return state.user.systemVersion;
  },
  hasSalesDuty: function hasSalesDuty(state) {
    return state.user.hasSalesDuty;
  },
  hasOperatorDuty: function hasOperatorDuty(state) {
    return state.user.hasOperatorDuty;
  },
  hasAccountingDuty: function hasAccountingDuty(state) {
    return state.user.hasAccountingDuty;
  },
  productSite: function productSite(state) {
    return state.user.productSite;
  }
};
export default getters;