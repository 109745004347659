import "core-js/modules/es6.number.constructor";
import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
import SystemMessageData from "../../data/SystemMessageData";
var attributes = {
  category: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '分类',
    filterOptions: SystemMessageData.CATEGORIES
  },
  read: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '状态',
    filterOptions: SystemMessageData.STATUSES
  },
  own: {
    type: Number,
    filterType: FilterParam.TYPE_CHECK,
    label: '只展示',
    checkLabel: '我参与的',
    trueLabel: 1,
    falseLabel: 0
  }
};
var SystemMessageModel = {
  id: Entity.ACCOUNT_SYSTEM_MESSAGE,
  name: Entity.ACCOUNT_SYSTEM_MESSAGE_NAME,
  label: Entity.ACCOUNT_SYSTEM_MESSAGE_LABEL,
  icon: Entity.ACCOUNT_SYSTEM_MESSAGE_ICON,
  listUri: '/message/index',
  createUri: null,
  updateUri: null,
  attributes: attributes
};
export default SystemMessageModel;