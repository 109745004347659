var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ConditionPanelItem",
    {
      attrs: {
        span: _vm.span,
        label: _vm.computedLabel,
        selected: !_vm.emptyValue,
      },
    },
    [
      _c(
        "el-select",
        {
          staticClass: "width-percent-100",
          attrs: {
            value: _vm.value,
            placeholder: _vm.computedPlaceholder,
            clearable: _vm.c,
          },
          on: { change: _vm.onSingleAttributeChange },
        },
        _vm._l(_vm.computedOptions, function (option, index) {
          return _c("el-option", {
            key: _vm.attr + "_" + index,
            attrs: { value: parseInt(option.value), label: option.text },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }