import "core-js/modules/es6.number.constructor";
import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
import Common from "../../Common";
var attributes = {
  name: {
    type: String,
    label: '姓名',
    filterType: FilterParam.TYPE_NAME
  },
  gender: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '性别',
    filterOptions: Common.GENDERS
  },
  districts: {
    type: Array,
    label: '所在区域',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  }
};
var ResTravellerModel = {
  id: Entity.RES_TRAVELLER,
  name: Entity.RES_TRAVELLER_NAME,
  label: Entity.RES_TRAVELLER_LABEL,
  icon: Entity.RES_TRAVELLER_ICON,
  nameAttr: 'name',
  listUri: '/resource/tourist',
  createUri: '/resource/tourist/add',
  updateUri: '/resource/tourist/edit',
  attributes: attributes
};
export default ResTravellerModel;