var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "el-image",
        { style: _vm.imageStyle, attrs: { src: _vm.imageUrl, fit: "fill" } },
        [
          _c(
            "div",
            {
              staticClass: "image-slot",
              attrs: { slot: "error" },
              slot: "error",
            },
            [
              _c(
                "div",
                [
                  _c("svg-icon", {
                    style: _vm.errorIconStyle,
                    attrs: { iconClass: _vm.errorIcon },
                  }),
                  _vm._v(" "),
                  _vm.errorDesc
                    ? _c("p", [_vm._v(_vm._s(_vm.errorDesc))])
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }