var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ConditionPanelItem",
    {
      attrs: {
        span: _vm.span,
        selected: !_vm.emptyValue,
        label: _vm.computedLabel,
      },
    },
    [
      _c("Address", {
        attrs: {
          placeholder: _vm.computedPlaceholder,
          type: _vm.computedType,
          value: _vm.value,
          callback: _vm.onDistrictAttributeChange,
        },
        on: {
          "update:value": function ($event) {
            _vm.value = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }