import "core-js/modules/es6.number.constructor";
import { Entity } from "../Entity";
import FilterParam from "../param/FilterParam";
import FileData from "../data/FileData";
var attributes = {
  name: {
    type: String,
    label: '名称',
    filterType: FilterParam.TYPE_NAME
  },
  file_type: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '类型',
    filterOptions: FileData.TYPES
  }
};
var FileModel = {
  id: Entity.FILE,
  name: Entity.FILE_NAME,
  label: Entity.FILE_LABEL,
  icon: Entity.FILE_ICON,
  nameAttr: 'name',
  listUri: '/file/index',
  createUri: null,
  updateUri: null,
  attributes: attributes
};
export default FileModel;