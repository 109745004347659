import "core-js/modules/es6.number.constructor";
import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
var attributes = {
  landscape_name: {
    type: String,
    label: '名称',
    filterType: FilterParam.TYPE_NAME
  },
  manager_id: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '资源对接人',
    filterOptionType: FilterParam.OPTIONS_USER_ID_NAME
  },
  districts: {
    type: Array,
    label: '所在区域',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  }
};
var ResLandscapeModel = {
  id: Entity.RES_LANDSCAPE,
  name: Entity.RES_LANDSCAPE_NAME,
  label: Entity.RES_LANDSCAPE_LABEL,
  icon: Entity.RES_LANDSCAPE_ICON,
  nameAttr: 'landscape_name',
  listUri: '/resource/landscape',
  createUri: '/resource/landscape/add',
  updateUri: '/resource/landscape/edit',
  attributes: attributes
};
export default ResLandscapeModel;