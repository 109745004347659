var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "previews-wrapper" }, [
    _vm.show
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "previews",
          },
          [
            _c("iframe", {
              staticClass: "iframe",
              attrs: { id: "ifra", src: _vm.previewUrl },
            }),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "share-wrapper" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }