import { Entity } from "../Entity";
import FilterParam from "../param/FilterParam";
var attributes = {
  name: {
    type: String,
    label: '名称',
    filterType: FilterParam.TYPE_NAME
  }
};
var RecycleModel = {
  id: Entity.RECYCLE,
  name: Entity.RECYCLE_NAME,
  label: Entity.RECYCLE_LABEL,
  icon: Entity.RECYCLE_ICON,
  listUri: '/recycle/index',
  createUri: null,
  updateUri: null,
  attributes: attributes
};
export default RecycleModel;