import "core-js/modules/es6.number.constructor";
import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
import ResData from "../../data/ResData";
import Common from "../../Common";
var attributes = {
  guide_name: {
    type: String,
    label: '姓名',
    filterType: FilterParam.TYPE_NAME
  },
  gender: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '性别',
    filterOptions: Common.GENDERS
  },
  certificate_level: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '证书等级',
    filterOptions: ResData.TOUR_GUIDE_CERTIFICATE_LEVELS
  },
  regionDistricts: {
    type: Array,
    label: '熟悉区域',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  },
  districts: {
    type: Array,
    label: '所在区域',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  }
};
var ResTourGuideModel = {
  id: Entity.RES_TOUR_GUIDE,
  name: Entity.RES_TOUR_GUIDE_NAME,
  label: Entity.RES_TOUR_GUIDE_LABEL,
  icon: Entity.RES_TOUR_GUIDE_ICON,
  nameAttr: 'guide_name',
  listUri: '/resource/guide',
  createUri: '/resource/guide/add',
  updateUri: '/resource/guide/edit',
  attributes: attributes
};
export default ResTourGuideModel;