export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    callback: {
      type: Function,
      default: function _default() {
        return void 0;
      }
    }
  },
  computed: {
    modelId: function modelId() {
      return this.model.id;
    },
    modelLabel: function modelLabel() {
      return this.model.label;
    }
  },
  methods: {
    getAttrConfig: function getAttrConfig(attr) {
      return this.model.attributes[attr];
    },
    getAttrLabel: function getAttrLabel(attr) {
      var attrConfig = this.getAttrConfig(attr);
      return attrConfig ? attrConfig.label : null;
    },
    getAttrPlaceholder: function getAttrPlaceholder(attr) {
      var attrConfig = this.getAttrConfig(attr);
      return attrConfig ? attrConfig.placeholder : null;
    },
    getAttrFilterType: function getAttrFilterType(attr) {
      var attrConfig = this.getAttrConfig(attr);
      return attrConfig ? attrConfig.filterType : null;
    },
    isNameFilter: function isNameFilter(attr) {
      return this.getAttrFilterType(attr) === this.$FilterParam.TYPE_NAME;
    },
    isDistrictFilter: function isDistrictFilter(attr) {
      return this.getAttrFilterType(attr) === this.$FilterParam.TYPE_DISTRICT_SELECTION;
    },
    isDateRangePickerFilter: function isDateRangePickerFilter(attr) {
      return this.getAttrFilterType(attr) === this.$FilterParam.TYPE_DATE_RANGE_PICKER;
    },
    isSingleSelectionFilter: function isSingleSelectionFilter(attr) {
      return this.getAttrFilterType(attr) === this.$FilterParam.TYPE_SINGLE_SELECTION;
    },
    isCheckFilter: function isCheckFilter(attr) {
      return this.getAttrFilterType(attr) === this.$FilterParam.TYPE_CHECK;
    },
    onAttributeChange: function onAttributeChange(attr, val) {
      return this.callback(attr, val);
    }
  }
};