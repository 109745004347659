import "core-js/modules/es6.number.constructor";
import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
var attributes = {
  hotel_name: {
    type: String,
    label: '名称',
    filterType: FilterParam.TYPE_NAME
  },
  manager_id: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '资源对接人',
    filterOptionType: FilterParam.OPTIONS_USER_ID_NAME
  },
  districts: {
    type: Array,
    label: '所在区域',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  }
};
var ResHotelModel = {
  id: Entity.RES_HOTEL,
  name: Entity.RES_HOTEL_NAME,
  label: Entity.RES_HOTEL_LABEL,
  icon: Entity.RES_HOTEL_ICON,
  nameAttr: 'hotel_name',
  listUri: '/resource/hotel',
  createUri: '/resource/hotel/add',
  updateUri: '/resource/hotel/edit',
  attributes: attributes
};
export default ResHotelModel;