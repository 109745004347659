import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
var attributes = {
  restaurant_name: {
    type: String,
    label: '名称',
    filterType: FilterParam.TYPE_NAME
  },
  districts: {
    type: Array,
    label: '所在区域',
    filterType: FilterParam.TYPE_DISTRICT_SELECTION,
    filterDistrictSelectionType: 3
  }
};
var ResRestaurantModel = {
  id: Entity.RES_RESTAURANT,
  name: Entity.RES_RESTAURANT_NAME,
  label: Entity.RES_RESTAURANT_LABEL,
  icon: Entity.RES_RESTAURANT_ICON,
  nameAttr: 'restaurant_name',
  listUri: '/resource/restaurant',
  createUri: '/resource/restaurant/add',
  updateUri: '/resource/restaurant/edit',
  attributes: attributes
};
export default ResRestaurantModel;