var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-search", class: { show: _vm.show } },
    [
      _c("i", {
        staticClass: "el-icon-search",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.click($event)
          },
        },
      }),
      _vm._v(" "),
      _vm.selectShow
        ? _c(
            "el-select",
            {
              ref: "headerSearchSelect",
              staticClass: "header-search-select",
              attrs: {
                "remote-method": _vm.querySearch,
                filterable: "",
                remote: "",
                clearable: "",
                placeholder: "搜索产品、资源",
              },
              on: { change: _vm.change, focus: _vm.querySearch },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword",
              },
            },
            _vm._l(_vm.searchItems, function (item) {
              return _c(
                "el-option",
                {
                  key: item.id,
                  attrs: { value: item.id, label: _vm.itemLabel(item) },
                },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.highlightItemLabel(item)),
                    },
                  }),
                ]
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }